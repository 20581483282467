
class StockApi {


    getArrivaleState()
    {
        var url = apiUrl + 'stocks/arrival-states';
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }
    getForSaleList()
    {
        var url = apiUrl + 'stocks/forSale/';
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });

    }

    // getList() {
    //     var url = apiUrl + 'stocks';
    //     return api.call('get', url)
    //         .then(({ data }) => {
    //             return data
    //         });
    // }

    getList({ 
        page = 1, 
        itemsPerPage = 10, 
        sorter = null, 
        filters = {}, 
        search = '',
        priceMin = '',
        priceMax = '', 
        isOpen = false,
    }) {
        let url = apiUrl + 'stocks';

        // Build query string parameters
        const params = new URLSearchParams();

        params.append('page', page);
        params.append('itemsPerPage', itemsPerPage);

        if (sorter?.column) {
            params.append('sortColumn', sorter.column);
            params.append('sortAsc', sorter.asc);
        }

        if (search) {
            params.append('search', search);
        }

        if (priceMin) {
            params.append('priceMin', priceMin);
        }
        if (priceMax) {
            params.append('priceMax', priceMax);
        }

        params.append('isOpen', isOpen);
        
        // Append filters to the query string
        Object.keys(filters).forEach((key) => {
            if (filters[key]) {
                params.append(`filters[${key}]`, filters[key]);
            }
        });

        // Add query string to URL
        url += `?${params.toString()}`;

        console.log(url);
        
        return api.call('get', url).then(({ data }) => {
            return data; // Expected to return { items, totalItems }
        });
    }


    getListByFilter(filters) {
        const params = new URLSearchParams(filters).toString();
        var url = apiUrl + 'stocks?' + params;
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }

    getNextStockNumber() {
        var url = apiUrl + 'stocks/GetNextStockNumber';
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }

    get(id) {
        var url = apiUrl + 'stocks/';
        return api.call('get', url + id)
            .then(({ data }) => {
                return data
            });
    }

    create(data) {
        var url = apiUrl + 'stocks';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            });

    }
    
    
    updateImagePosition(data) {
        var url = apiUrl + 'stocks/imageposition/';
        return api.call('put', url + data.id, data)
            .then(({ data }) => {
                return data
            });
    }

    updateDefaultImages(data) {
        var url = apiUrl + 'stocks/defaultimage/';
        return api.call('put', url + data.id, data)
            .then(({ data }) => {
                return data
            });
    }

    update(data) {
        var url = apiUrl + 'stocks/';
        return api.call('put', url + data.id, data)
            .then(({ data }) => {
                return data
            });
    }
    delete(id) {
        var url = apiUrl + 'stocks/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

    deleteVehicleImage(imageId) {
        var url = apiUrl + 'stocks/image/';
        return api.call('delete', url + imageId)
            .then(({ data }) => {
                return data
            });
    }


    updateStockStatus(data) {
        var url = apiUrl + 'stocks/UpdateStockStatus/';
        return api.call('put', url + data.stockId, data)
            .then(({ data }) => {
                return data
            });
    }

    updateRemarks(data) {
        var url = apiUrl + 'stocks/UpdateRemarks/';
        return api.call('put', url + data.stockId, data)
            .then(({ data }) => {
                return data
            });
    }

    updateVehicleImages(id, data) {
        var url = apiUrl + 'stocks/UpdateVehicleImages/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    updatePuspakomB2SlipDocuments(id, data) {
        var url = apiUrl + 'stocks/UpdatePuspakomB2SlipDocuments/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    removePuspakomB2SlipDocuments(registrationId, documentId) {
        var url = apiUrl + 'stocks/RemovePuspakomB2SlipDocument/' + registrationId + '/' + documentId;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }

    updatePuspakomB7SlipDocuments(id, data) {
        var url = apiUrl + 'stocks/UpdatePuspakomB7SlipDocuments/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    removePuspakomB7SlipDocuments(registrationId, documentId) {
        var url = apiUrl + 'stocks/RemovePuspakomB7SlipDocument/' + registrationId + '/' + documentId;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }

    updateJpjDaftarDocuments(id, data) {
        var url = apiUrl + 'stocks/UpdateJpjDaftarDocuments/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    removeJpjDaftarDocuments(registrationId, documentId) {
        var url = apiUrl + 'stocks/RemoveJpjDaftarDocument/' + registrationId + '/' + documentId;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }


    updateJpjHakMilikDocuments(id, data) {
        var url = apiUrl + 'stocks/UpdateJpjHakMilikDocuments/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    removeJpjHakMilikDocuments(registrationId, documentId) {
        var url = apiUrl + 'stocks/RemoveJpjHakMilikDocument/' + registrationId + '/' + documentId;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }

    updateJpjGeranDocuments(id, data) {
        var url = apiUrl + 'stocks/UpdateJpjGeranDocuments/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    removeJpjGeranDocuments(registrationId, documentId) {
        var url = apiUrl + 'stocks/RemoveJpjGeranDocument/' + registrationId + '/' + documentId;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }


    updateBolDocuments(id, data) {
        var url = apiUrl + 'stocks/UpdateBolDocuments/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    removeBolDocuments(importId, documentId) {
        var url = apiUrl + 'stocks/RemoveBolDocument/' + importId + '/' + documentId;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }

    updateK8Documents(id, data) {
        var url = apiUrl + 'stocks/UpdateK8Documents/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    removeK8Documents(clearanceId, documentId) {
        var url = apiUrl + 'stocks/RemoveK8Document/' + clearanceId + '/' + documentId;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }


    //
    updateK1Documents(id, data) {
        var url = apiUrl + 'stocks/UpdateK1Documents/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    removeK1Documents(clearanceId, documentId) {
        var url = apiUrl + 'stocks/RemoveK1Document/' + clearanceId + '/' + documentId;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }
    //
    updateLouDocuments(id, data) {
        var url = apiUrl + 'stocks/UpdateLouDocuments/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    removeLouDocuments(loanId, documentId) {
        var url = apiUrl + 'stocks/RemoveLouDocument/' + loanId + '/' + documentId;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }

    addAdministrativeCostItem(id, data) {
        var url = apiUrl + 'stocks/AddAdministrativeCostItem/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    updateAdministrativeCostItem(id, data) {
        var url = apiUrl + 'stocks/UpdateAdministrativeCostItem/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    removeAdministrativeCostItem(id) {
        var url = apiUrl + 'stocks/RemoveAdministrativeCostItem/' + id;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }

    addExpenseItem(id, data) {
        var url = apiUrl + 'stocks/AddExpenseItem/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }


    removeExpenseItem(id) {
        var url = apiUrl + 'stocks/RemoveExpenseItem/' + id;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }
    updateExpenseItem(id, data) {
        var url = apiUrl + 'stocks/UpdateExpenseItem/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    addArrivalItem(id, data) {
        var url = apiUrl + 'stocks/AddArrivalCheckLisItem/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }


    removeArrivalItem(id) {
        var url = apiUrl + 'stocks/RemoveArrivalCheckLisItem/' + id;
        return api.call('put', url)
            .then(({ data }) => {
                return data
            });
    }
    updateArrivalItem(id, data) {
        var url = apiUrl + 'stocks/UpdateArrivalItem/';
        return api.call('put', url + id, data)
            .then(({ data }) => {
                return data
            });
    }
}

export default StockApi;